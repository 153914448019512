import styled, { createGlobalStyle } from "styled-components"

const GUTTER = 30

export const MasonryStyles = createGlobalStyle`
  .my-masonry-grid {
    display: flex;
    width: auto;
    margin-left: ${GUTTER * -1}px;
  }

  .my-masonry-grid_column {
    padding-left: ${GUTTER}px; /* gutter size */
    background-clip: padding-box;
  }

  .my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
    background: grey;
    margin-bottom: ${GUTTER}px;
  }
`

export const ImageWrapper = styled.button`
  position: relative;
  width: 100%;
  border: 0;
  padding: 0;
  margin-bottom: ${GUTTER}px;
  background: transparent;
  border-radius: 1px;
  box-shadow: rgb(154 160 185 / 50%) 0px 5px 10px,
    rgb(166 173 201 / 20%) 0px 15px 40px;
  cursor: pointer;
  transition: 0.2s;
  overflow: hidden;

  &:hover {
    transform: scale(1.1);
    z-index: 1;
  }
`
