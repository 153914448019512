import styled, { createGlobalStyle } from "styled-components"

export const ModalStyles = createGlobalStyle`
  .ReactModal__Overlay {
    z-index: 100;
    
      @supports (backdrop-filter: blur()) {
      background: transparent;
      backdrop-filter: blur(5px);
    }
    
    @supports not (backdrop-filter: blur()) {
      background: rgba(0,0,0,0.4);
    }
  }

  .ReactModal__Content {
    z-index: 100;
    background: transparent !important;
    border: 0 !important;
    padding: 0 !important;
    inset: 10px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ReactModal__Body--open {
    overflow: hidden;
  }
`

export const ModalImage = styled.img`
  height: 100%;
  border-radius: 0.5rem;
  box-shadow: rgb(154 160 185 / 50%) 0px 5px 10px,
    rgb(166 173 201 / 20%) 0px 15px 40px;
  object-fit: contain;
  overflow: hidden;
`

export const Close = styled.button`
  background: transparent;
  border: 0;
  padding: 0;
  font-size: 1.5rem;
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    transform: scale(1.1);
  }
`
