import React, { useState } from "react"
import Modal from "react-modal"
import Masonry from "react-masonry-css"
import { graphql } from "gatsby"
import Img, { FluidObject } from "gatsby-image"

import { useScrollToTop } from "../hooks/useScrollToTop"
import { Layout } from "../components/Layout"
import { Content, Home, Header, Title, Page } from "../components/PageContent"
import { MasonryStyles, ImageWrapper } from "../components/Masonry.style"
import { ModalStyles, ModalImage, Close } from "../components/Modal.style"

type Props = {
  data: {
    allFile: {
      edges: [
        {
          node: {
            base: string
            childImageSharp: {
              fluid: FluidObject
            }
          }
        }
      ]
    }
  }
}

const breakpointColumnsObj = {
  default: 3,
  700: 2,
}

Modal.setAppElement("#___gatsby")

const POI: React.FC<Props> = ({ data }) => {
  useScrollToTop()

  const [selectedImage, setSelectedImage] = useState<FluidObject>()

  const closeModal = () => setSelectedImage(undefined)
  const modalContentClick = (e: React.MouseEvent) => {
    if ((e.target as HTMLElement).id === "modal-content") {
      closeModal()
    }
  }

  return (
    <>
      <ModalStyles />
      <MasonryStyles />
      <Layout title="Muetry - Poetry Of the Internet.">
        <Content>
          <Home href="/">Muetry.</Home>
          <Page>
            <Header>
              <Title>Poetry Of the Internet.</Title>

              <p style={{ maxWidth: "550px", margin: "2rem auto" }}>
                Glimpses of poetry across the internet that I happen to stumble
                upon. It&apos;s not all that common to find poetry and often it
                is done in distasteful humour. This is my collection.
              </p>

              <br />
              <br />

              <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
              >
                {data.allFile.edges
                  .map((img) => (
                    <ImageWrapper
                      key={img.node.base}
                      onClick={() =>
                        setSelectedImage(img.node.childImageSharp.fluid)
                      }
                    >
                      <Img
                        fadeIn
                        fluid={img.node.childImageSharp.fluid}
                        alt="POI image"
                      />
                    </ImageWrapper>
                  ))
                  .reverse()}
              </Masonry>
            </Header>
          </Page>
        </Content>
      </Layout>

      {selectedImage && (
        <Modal
          isOpen
          onRequestClose={closeModal}
          contentElement={(props, children) => (
            <div {...props} onClick={modalContentClick} id="modal-content">
              {children}
            </div>
          )}
        >
          <Close onClick={closeModal}>Close</Close>
          <ModalImage
            sizes={selectedImage.sizes}
            srcSet={selectedImage.srcSet}
          />
        </Modal>
      )}
    </>
  )
}

export const pageQuery = graphql`
  query {
    allFile(filter: { relativeDirectory: { eq: "poi" } }) {
      edges {
        node {
          base
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default POI
